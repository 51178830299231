<template>
  <div class="cash-withdrawal">
    <div class="nav acea-row">
      <div
        v-for="(item, index) in navList"
        class="item font-color-red"
        @click="swichNav(index, item)"
        :key="index"
      >
        <div class="line bg-color-red" :class="currentTab === index + 1 ? 'on' : ''"></div>
        <div class="iconfont" :class="item.icon + ' ' + (currentTab === index + 1  ? 'on' : '')"></div>
        <div>{{ item.name }}</div>
      </div>
    </div>
    <div class="wrapper">
      <div :hidden="currentTab !== 1" class="list">
        <div class="item acea-row row-between-wrapper">
          <div class="name">微信号</div>
          <div class="input">
            <input placeholder="请填写微信号" v-model="post.weixin" />
          </div>
        </div>
        <div class="item acea-row row-between-wrapper">
          <div class="name">提现</div>
          <div class="input">
            <input :placeholder="'最低提现金额' + minPrice" v-model="post.money" />
          </div>
        </div>
        <div class="tip">可提现金额: {{ commission }}</div>
        <div class="tip">实际到账金额: {{ commissionCount }}</div>
        <div class="bnt bg-color-red" @click="submitted(0)">提现</div>
      </div>
      <div :hidden="currentTab !== 2" class="list">
        <div class="item acea-row row-between-wrapper">
          <div class="name">账号</div>
          <div class="input">
            <input placeholder="请填写您的账号" v-model="post.alipay_code" />
          </div>
        </div>
        <div class="item acea-row row-between-wrapper">
          <div class="name">提现</div>
          <div class="input">
            <input :placeholder="'最低提现金额' + minPrice" v-model="post.money" />
          </div>
        </div>
        <div class="tip">实际到账金额: {{ commissionCount }}</div>
        <div class="bnt bg-color-red" @click="submitted(1)">提现</div>
      </div>
      <div :hidden="currentTab !== 3" class="list">
        <div class="item acea-row row-between-wrapper">
          <div class="name">提现</div>
          <div class="input">
            <input :placeholder="'最低提现金额' + minPrice" v-model="post.money" />
          </div>
        </div>
        <div class="tip">实际到账金额: {{ commissionCount }}</div>
        <div class="bnt bg-color-red" @click="submitted(1)">提现</div>
      </div>
      <div :hidden="currentTab !== 4" class="list">
        <div class="item acea-row row-between-wrapper">
          <div class="name">银行卡号</div>
          <div class="input">
            <input placeholder="请填写您的银行卡号" v-model="post.mycard" />
          </div>
        </div>
        <div class="item acea-row row-between-wrapper">
          <div class="name">提现</div>
          <div class="input">
            <input :placeholder="'最低提现金额' + minPrice" v-model="post.money" />
          </div>
        </div>
        <div class="tip">实际到账金额: {{ commissionCount }}</div>
        <div class="bnt bg-color-red" @click="submitted(1)">提现</div>
      </div>
    </div>
  </div>
</template>
<script>
import User from "@api/user";
export default {
  name: "UserCash",
  components: {},
  props: {},
  // 提现方式 1余额 2微信钱包 3支付宝 4银行卡
  data: function() {
    return {
      navList: [
        { name: "微信", type: "2", icon: "icon-weixin2" },
        { name: "支付宝", type: "3", icon: "icon-icon34" },
        { name: "余额", type: "1", icon: "icon-icon34" },
        { name: "银行卡", type: "4", icon: "icon-icon34" }
      ],
      post: {
        extract_type: "1",
        alipay_code: "",
        money: "",
        name: "",
        bankname: "",
        cardnum: "",
        weixin: "",
        mycard: ""
      },
      config_obj: {},
      currentTab: 1,
      minPrice: 0,
      banks: [],
      commission: Number,
      commissionCount: 0
    };
  },
  watch: {
    // 计算实际获得佣金
    'post.money': function(value) {
      // 费率
      let fee_rate = Number(this.config_obj.fee_rate) / 100; 
      if (value > Number(this.config_obj.free_min) && value < Number(this.config_obj.free_max)) {
        this.commissionCount = value;
      } else {
        this.commissionCount = value - Math.floor(value * fee_rate * 100) / 100;
      }
    }
  },
  mounted: function() {
    this.commission = this.$route.query.money;
    this.getyConfig();
  },
  methods: {
    // 获取提现配置信息
    async getyConfig() {
      try {
        const res = await User.getWithdConfig();
        if (res.code == 200) {
          this.config_obj = res.data;
          // this.minPrice = res.data.min;
          let arr_2 = res.data.withraw_wallet.split(',');
          this.navList  = this.navList.filter((item) => {
            return arr_2.indexOf(item.type) > -1
          })
        }
      } catch (error) {
        console.log(error);
      }
    },
    swichNav: function(index, item) {
      this.currentTab = index + 1;
      this.post.extract_type = item.type;
    },
    async submitted(idx) {
      let money = "";
      let account = "";
      try {
        if (this.post.money < 0) {
          throw "请重新输入金额";
        } else {
          switch (idx) {
            case 0:
                money = this.post.money;
                account = this.post.weixin;
              break;
            case 1:
                money = this.post.money;
                account = this.post.weixin;
              break;
            case 2:
                money = this.post.money;
                account = "";
              break;
            case 3:
                money = this.post.money;
                account = this.post.mycard;
              break;          
            default:
              break;
          }
        }
        const res = await User.goCashMoney(money, this.post.extract_type,  account,  );
        if(res.code == 200) {
          this.$toast("提交成功")
          this.$router.go(-1)
        } else {
          this.$toast(res.msg)
        }
        console.log(res);
      } catch (error) {
        this.$toast(error);
      }
    },
    save: function() {}
  }
};
</script>

<style lang="scss" scoped>
@import "../index.scss";
/*银行卡提现*/
.cash-withdrawal {
  height: 100%;
  background: #fff;
}
.cash-withdrawal .nav {
  height: 65px;
  box-shadow: 0 5px 5px #f8f8f8;
  -webkit-box-shadow: 0 5px 5px #f8f8f8;
  -moz-box-shadow: 0 5px 5px #f8f8f8;
  -o-box-shadow: 0 5px 5px #f8f8f8;
}

.cash-withdrawal .nav .item {
  font-size: 14px;
  flex: 1;
  -webkit-flex: 1;
  -o-flex: 1;
  -ms-flex: 1;
  text-align: center;
}

.cash-withdrawal .nav .item ~ .item {
  border-left: 1px solid #f0f0f0;
}

.cash-withdrawal .nav .item .iconfont {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #e93323;
  text-align: center;
  line-height: 14px;
  margin: 0 auto 3px auto;
  font-size: 12px;
}

.cash-withdrawal .nav .item .iconfont.on {
  background-color: #e93323;
  color: #fff;
  border-color: #e93323;
}

.cash-withdrawal .nav .item .line {
  width: 1px;
  height: 10px;
  margin: 0 auto;
  transition: height 0.3s;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -o-transition: height 0.3s;
}

.cash-withdrawal .nav .item .line.on {
  height: 20px;
}

.cash-withdrawal .wrapper .list {
  padding: 0 15px;
}

.cash-withdrawal .wrapper .list .item {
  border-bottom: 1px solid #eee;
  height: 53px;
  font-size: 15px;
  color: #333;
}

.cash-withdrawal .wrapper .list .item .name {
  width: 65px;
}

.cash-withdrawal .wrapper .list .item .input {
  width: 253px;
}

.cash-withdrawal .wrapper .list .item .input input::placeholder {
  color: #bbb;
}

.cash-withdrawal .wrapper .list .tip {
  font-size: 14px;
  color: #d92027;
  margin-top: 13px;
}

.cash-withdrawal .wrapper .list .bnt {
  font-size: 16px;
  color: #fff;
  width: 345px;
  height: 45px;
  text-align: center;
  border-radius: 25px;
  line-height: 45px;
  margin: 32px auto;
}

.cash-withdrawal .wrapper .list .tip2 {
  font-size: 14px;
  color: #999;
  text-align: center;
  margin: 22px 0 10px 0;
}

.cash-withdrawal .wrapper .list .value {
  height: 68px;
  line-height: 68px;
  border-bottom: 1px solid #eee;
  width: 345px;
  margin: 0 auto;
}

.cash-withdrawal .wrapper .list .value input {
  font-size: 40px;
  color: #282828;
  height: 68px;
  text-align: center;
  width: 100%;
}

.cash-withdrawal .wrapper .list .value input::placeholder {
  color: #bbb;
}
</style>
